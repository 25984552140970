import React from 'react'

const GithubDark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <g dataname="Layer 2">
      <g dataname="Layer 1">
        <circle className="u-fill--primary u-stroke--secondary" cx="50" cy="50" r="49" strokeWidth="3" />
        <path
          d="M44.88 7.45C24.73 9.64 8.52 25.85 6.33 45.56a43.5 43.5 0 0 0 30.23 46.87V82.36a18.52 18.52 0 0 1-4 .43c-6.13 0-8.76-5.25-9.19-8.32a9.18 9.18 0 0 0-2.63-4.38c-1.32-.44-1.76-.44-1.76-.87 0-.88 1.32-.88 1.76-.88 2.63 0 4.82 3.07 5.69 4.38 2.19 3.5 4.82 4.38 6.13 4.38a8.74 8.74 0 0 0 4-.88c.44-3.06 1.75-6.13 4.38-7.88-10.08-2.19-17.52-7.89-17.52-17.52a20.71 20.71 0 0 1 5.25-13.15 16.19 16.19 0 0 1-.87-6.13 15.07 15.07 0 0 1 1.31-7s6.13 0 12.27 5.7a23.58 23.58 0 0 1 8.32-1.32 28.11 28.11 0 0 1 8.76 1.32 18.89 18.89 0 0 1 12.27-5.7 21.94 21.94 0 0 1 .87 7c0 3.51-.44 5.26-.87 6.13A19.79 19.79 0 0 1 76 50.82c0 9.63-7.44 15.33-17.52 17.52a13.49 13.49 0 0 1 4.38 10.07v14.46a43.78 43.78 0 0 0 30.65-41.62c0-26.25-22.35-46.87-48.63-43.8z"
          className="u-fill--primary u-stroke--secondary"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2.5"
        />
      </g>
    </g>
  </svg>
)

export default GithubDark
