import React from 'react'

const LinkedInDark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <g dataname="Layer 2">
      <g dataname="Layer 1">
        <circle className="u-fill--primary u-stroke--secondary" cx="50" cy="50" r="49" strokeWidth="3" />
        <path
          className="u-fill--secondary"
          d="M31.52 26.64a7.25 7.25 0 0 0-4.61 1.47A5 5 0 0 0 25 32c0 3 2.67 5.29 6.2 5.43a1.12 1.12 0 0 0 .31 0 7.12 7.12 0 0 0 4.69-1.55A5.14 5.14 0 0 0 38 32v-.06c-.14-3-2.81-5.3-6.48-5.3zm0 2.15c2.84 0 4.24 1.39 4.36 3.24a2.92 2.92 0 0 1-1.06 2.27 5 5 0 0 1-3.3 1c-2.83 0-4.36-1.53-4.36-3.33a2.73 2.73 0 0 1 1.1-2.22 5.1 5.1 0 0 1 3.26-.96zm-5.43 10.88A1.08 1.08 0 0 0 25 40.75v31.53a1.08 1.08 0 0 0 1.09 1.08H37a1.08 1.08 0 0 0 1-1.08V40.75a1.08 1.08 0 0 0-1-1.08zm16.3 0a1.08 1.08 0 0 0-1.09 1.08v31.53a1.08 1.08 0 0 0 1.09 1.08h10.87a1.08 1.08 0 0 0 1.09-1.08V54.34a3.81 3.81 0 0 1 7.61 0v17.94A1.08 1.08 0 0 0 63 73.36h10.91A1.08 1.08 0 0 0 75 72.28V53.8c0-4.49-1.32-8-3.54-10.46a11.52 11.52 0 0 0-8.65-3.67 13 13 0 0 0-8.46 2.83v-1.75a1.08 1.08 0 0 0-1.09-1.08zm-15.22 2.17h8.7v29.35h-8.7zm16.31 0h8.69v2.87a1.09 1.09 0 0 0 1.9.73 12.39 12.39 0 0 1 8.74-3.6 9.26 9.26 0 0 1 7 3c1.8 2 3 4.92 3 9v17.35h-8.7V54.34a1.12 1.12 0 0 0-.11-.44 5.94 5.94 0 0 0-11.87.44v16.85h-8.65z"
        />
      </g>
    </g>
  </svg>
)

export default LinkedInDark
